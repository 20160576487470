import { MutationTree }         from 'vuex';
import { Group, State }                       from '@/views/administration/views/administration-groups/administration-groups.types';
import { AdministrationParams, SidePanelState } from '@/types';
import { TableRow }                           from '@/components/common/table/table.types';


export const mutations: MutationTree<State> = {
  setRawGroupsData(state, groupsData: TableRow[]): void {
    state.rawGroupsData = groupsData;
  },
  setCurrentItem(state, group: Group): void {
    state.selectedGroup = group;
  },
  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },
  clearSelectedGroup(state): void {
    state.selectedGroup = null;
  },
  setSidePanelState(state, payload: SidePanelState): void {
    state.sidePanelState = payload
  },
  setGroupsToReplace(state, payload: Group[]): void {
    state.groupsToReplace = payload
  }

}
