
import { required }                                         from '@vuelidate/validators';
import { computed, ComputedRef, defineComponent, reactive } from 'vue';
import i18n                                                 from '@/i18n';
import { Notification }                                     from '@/components/notification/notification';
import { useTranslations }                                  from '@/hooks/use-translations';
import CFormInput                                           from '@/components/common/form/form-input/form-input.component.vue';
import CColorPicker                                         from '@/components/common/color-picker/color-picker.component.vue';
import useVuelidate, { ValidationArgs }                     from '@vuelidate/core';
import { useStore }                                         from 'vuex';
import { FormMode }                                         from '@/views/administration/administration.types';
import CAdministrationFormButtons                           from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { GroupForm, GroupFormRules }                        from '@/views/administration/views/administration-groups/administration-groups.types';
import { GLOBAL }                                           from '@/helpers/variables';


export default defineComponent({
  name: 'CGroupsForm',
  components: { CAdministrationFormButtons, CColorPicker, CFormInput },
  props: {
    formMode: {
      type: String,
      required: true,
    },
  },
  emits: ['close-panel'],
  setup(props, { emit }) {
    const store = useStore();
    const formData: ComputedRef<GroupForm> = computed(() => store.getters['views/groups/selectedGroup']);
    const state: GroupForm = reactive({ ...formData.value });

    const rules: GroupFormRules = {
      name: { required },
      color: { required },
    };

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, state as any);

    const deleteGroup = async (): Promise<void> => {
      await store.dispatch('views/groups/deleteGroupEntity', state.uuid);
      await store.dispatch('views/groups/getRawGroupsData');
      emit('close-panel');
    };

    const submitGroupsForm = async (formMode: FormMode, closePanel: boolean): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          switch (formMode) {
            case FormMode.ADD:
              await store.dispatch('views/groups/createNewGroup', { name: state.name, color: state.color });
              await store.dispatch('views/groups/getRawGroupsData');
              break;
            case FormMode.EDIT:
              await store.dispatch('views/groups/editGroupEntity', {
                uuid: state.uuid,
                data: { color: state.color, name: state.name }
              });
              await store.dispatch('views/groups/getRawGroupsData');
              break;
            default:
              break;
          }
          v$.value.$reset();
          state.name = '';
          state.color = '';
          if (closePanel) {
            emit('close-panel');
          }
        } catch (e) {
          throw Error(e);
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
      }
    };

    return {
      useTranslations,
      submitGroupsForm,
      deleteGroup,
      v$,
      state,
      FormMode,
    };
  },
});
