import { Module } from 'vuex';
import { State } from '@/views/administration/views/administration-groups/administration-groups.types';
import { RootState } from '@/store/store.types';
import { columnTypes } from '@/components/common/table/table.types';
import { actions } from '@/views/administration/views/administration-groups/store/administration-groups.actions';
import { mutations } from '@/views/administration/views/administration-groups/store/administration-groups.mutations';
import { getters }                   from '@/views/administration/views/administration-groups/store/administration-groups.getters';
import { FilterTypes, SidePanelState } from '@/types';



const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    groupsToReplace:[],
    selectedGroup: null,
    sidePanelState: SidePanelState.EDIT_ENTITY,
    title: 'groups',
    columns: [
      {
        name: 'name',
        type: columnTypes.TEXT,
        filter: FilterTypes.SORT,
      },
      {
        name: 'color',
        type: columnTypes.COLOR,
      },
    ],
    tableParams: {
      searchQuery: '',
      'sortBy[0].direction': 'ASC',
      'sortBy[0].field': 'name',
    },
    rawGroupsData: null,
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default store;
