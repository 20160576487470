import { ActionTree }                                                                          from 'vuex';
import { GroupPayload, State }                                                                           from '@/views/administration/views/administration-groups/administration-groups.types';
import { createEntity, deleteSingleEntity, getEntitiesToReplace, getSingleEntity, getTableData, replaceEntity, updateSingleEntity } from '@/views/administration/administration.service';
import { RootState }                                                                                                              from '@/store/store.types';
import i18n                                                                                    from '@/i18n';
import { GLOBAL }                                                                              from '@/helpers/variables';
import { Notification }                                                            from '@/components/notification/notification';
import { AdministrationParams, SidePanelState }                                      from '@/types';
import { AdministrationEndpoints, AdministrationPagination, DeleteEntityResponse } from '@/views/administration/administration.types';


export const actions: ActionTree<State, RootState> = {
  async getRawGroupsData({ commit, state, rootState }): Promise<void> {
    try {
      const { currentPage, pageSize } = rootState.pagination[
        AdministrationPagination.GROUPS
      ];
      const params: Partial<AdministrationParams> = state.tableParams;
      const allParams = {
        ...params,
        size: pageSize,
        page: currentPage,
      }
      const { data } = await getTableData(AdministrationEndpoints.GROUPS, allParams);
      commit('setRawGroupsData', data.content);
      commit('updatePaginationElements', {
        totalElements: data.totalElements,
        name: AdministrationPagination.GROUPS,
      }, { root: true });
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getGroupEntity({ commit }, userUuid: string): Promise<void> {
    try {
      const { data } = await getSingleEntity(AdministrationEndpoints.GROUPS, userUuid);
      commit('setCurrentItem', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async deleteGroupEntity({ dispatch }, groupUuid: string): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.GROUPS, groupUuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('groups.notifications.delete')
      );
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getGroupsToReplace', groupUuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );

      throw e.data;
    }
  },

  async createNewGroup(context, payload: GroupPayload): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.GROUPS, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('groups.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async editGroupEntity(
    context,
    payload: { uuid: string; data: GroupPayload }
  ): Promise<void> {
    try {
      await updateSingleEntity(AdministrationEndpoints.GROUPS, payload.uuid, payload.data);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('groups.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getGroupsToReplace({ commit }, groupUuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.GROUPS,groupUuid)
      commit('setGroupsToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async replaceGroup(context, { groupUuid, replaceToGroupUuid }: {groupUuid: string, replaceToGroupUuid: string}) {
    try {
      await replaceEntity(AdministrationEndpoints.GROUPS, groupUuid, replaceToGroupUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('groups.notifications.replace')
      );
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};
