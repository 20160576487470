
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
import CDeleteConfirmation                                from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue'
import CAdministrationTemplate                            from '@/views/administration/components/administration-template/administration-template.component.vue';
import { TableColumn, TableRow }                            from '@/components/common/table/table.types';
import CSidePanel                                         from '@/components/common/side-panel/side-panel.component.vue';
import CGroupsForm                                                 from '@/views/administration/views/administration-groups/components/groups-form/groups-form.component.vue';
import { AdministrationParams, SearchbarSelectItem, SidePanelState } from '@/types';
import { Group }                                                   from '@/views/administration/views/administration-groups/administration-groups.types';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import { useGlobals }                           from '@/hooks/use-globals';
import { AdministrationPagination, FormMode }               from '@/views/administration/administration.types';


export default defineComponent({
  name: 'VAdministrationGroups',
  components: { CGroupsForm, CSidePanel, CAdministrationTemplate, CDeleteConfirmation },
  setup() {
    const { store, i18n } = useGlobals();
    const columns: ComputedRef<TableColumn[]> = computed(() => store.state.views.groups.columns);
    const rows: ComputedRef<TableRow[]> = computed(() => store.getters[ 'views/groups/groupsTableData' ]);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => store.state.views.groups.tableParams);
    const groupsToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/groups/groupsToReplace'])
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const sidePanelTitle: Ref<string> = ref('');
    const formMode: Ref<FormMode> = ref(FormMode.ADD);
    const selectedGroup: ComputedRef<Group> = computed(() => store.state.views.groups.selectedGroup);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.groups.sidePanelState);


    store.commit('views/groups/setSidePanelState', SidePanelState.EDIT_ENTITY)
    store.commit('views/groups/setTableParams', []);
    store.dispatch('views/groups/getRawGroupsData');

    const openAddNewGroupPanel = (): void => {
      formMode.value = FormMode.ADD;
      sidePanelTitle.value = i18n.t('groups.sidePanel.addNewGroup');
      isSidePanelOpen.value = true;
    };
    const closeSidePanel = (): void => {
      store.commit('views/groups/clearSelectedGroup');
      store.commit('views/groups/setSidePanelState', SidePanelState.EDIT_ENTITY);
      isSidePanelOpen.value = false;
    };

    const openEditGroupPanel = async (groupUuid: string): Promise<void> => {
      closeSidePanel();
      isSidePanelOpen.value = false;
      await store.dispatch('views/groups/getGroupEntity', groupUuid);
      sidePanelTitle.value = i18n.t('groups.sidePanel.editGroup');
      formMode.value = FormMode.EDIT;
      isSidePanelOpen.value = true;
    };

    const editGroup = async ({ name, color, uuid }: Group): Promise<void> => {
      await store.dispatch('views/groups/editGroupEntity', { uuid: uuid, data: { color, name } });
      await store.dispatch('views/groups/getRawGroupsData');
    };

    const addNewGroup = async ({ name, color }: Group): Promise<void> => {
      await store.dispatch('views/groups/createNewGroup', { name, color });
      await store.dispatch('views/groups/getRawGroupsData');
    };

    const updateParams = (updatedParams: Partial<AdministrationParams>): void => {
      store.commit('views/groups/setTableParams', updatedParams);
      store.dispatch('views/groups/getRawGroupsData');
    };

    const goBackToEditForm = () => {
      store.commit('views/groups/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceGroupAndDelete = async (groupUuidToReplace: string) => {
      await store.dispatch('views/groups/replaceGroup', { groupUuid: selectedGroup.value.uuid, replaceToGroupUuid: groupUuidToReplace })
      store.dispatch('views/groups/getRawGroupsData')
      closeSidePanel()
    }

    return {
      rows,
      columns,
      params,
      isSidePanelOpen,
      closeSidePanel,
      addNewGroup,
      sidePanelTitle,
      selectedGroup,
      updateParams,
      openEditGroupPanel,
      editGroup,
      formMode,
      openAddNewGroupPanel,
      useGlobalTranslations,
      AdministrationPagination,
      sidePanelState,
      SidePanelState,
      useTranslations,
      groupsToReplace,
      goBackToEditForm,
      replaceGroupAndDelete
    };
  },
});
