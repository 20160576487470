import { GetterTree }       from 'vuex';
import { GroupForm, State } from '@/views/administration/views/administration-groups/administration-groups.types'
import { RootState }        from '@/store/store.types';
import { primaryColor } from '@/helpers/variables';

export const getters: GetterTree<State, RootState> = {
  groupsTableData(state) {
    if(state.rawGroupsData) {
      return state.rawGroupsData
    }
    return []
  },
  groupsToReplace(state) {
    return state.groupsToReplace.map(group => ({ label: group.name, uuid: group.uuid }))
  },
  selectedGroup(state):GroupForm {
    if(state.selectedGroup) {
      return {
        ...state.selectedGroup,
      }
    }
    return {
      name: '',
      color: primaryColor,
    }
  }
}
